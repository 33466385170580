<template>
  <Section>
    <div class="import-subview">
      <List
        :key="componentKey"
        :header="list.headers"
        :listData="analysisReports"
        :items="list.col"
        :item-key="list.key"
        :target="list.target"
        disableGroupActions
        disableCheckbox
        disableActions
      >

        <template v-slot:designation="{ item }">
          {{ item.designation }}
        </template>

        <template v-slot:cible="{ item }">
          {{ item.cible?.valeur }}
        </template>

        <template v-slot:organe="{ item }">
          {{ item.organes.length ? item.organes[0].designation : null }}
        </template>

        <template v-slot:to_import="{ item }">
          {{ helperService.round(((item.valide*100)/item.renseigne),0) }}%
        </template>

        <template v-slot:errors="{ item }">
          <template v-if="item.nbr_errors">
            <div class="error-block">
              <span>{{ item.nbr_errors }}&nbsp;erreurs</span>
              <Btn
                class="btn-error"
                :text="`Voir`"
                @click="openModal('variableErrors', item.errors)"
                color="accent"
                size="sm"
              />
            </div>
          </template>
          <template v-else>{{ item.nbr_errors }}</template>
        </template>
      </List>
    </div>
  </Section>

  <!-- Modals realizeEvent -->
  <Modal
    title="Erreurs de l'importation"
    :active="modal.variableErrors"
    :data="modalData"
    @modal-close="closeModal('variableErrors')"
    size="full"
    no-scroll
    no-padding
  >
    <template v-slot:modal-body="{ data }">
      <List
        v-if="data.length"
        :key="componentKey"
        :header="list_errors.headers"
        :listData="data"
        :items="list_errors.col"
        :item-key="list_errors.key"
        disableGroupActions
        disableCheckbox
        disableActions
        class="list-error-modal"
      >

        <template v-slot:number="{ item }">
          <strong>{{ String(item.id).padStart(2, '0') }}</strong>
        </template>

        <template v-slot:details="{ item }">
          <div v-for="(error, index) in item.errors" :key="index" class="error-list-item">
            {{ error }}
          </div>
        </template>
      </List>
    </template>

    <template v-slot:modal-footer>
      <Btn text="Fermer" @click="closeModal('variableErrors')" />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Modal from '@/components/layout/Modal.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'EssaiImportDataAssignementView',

  components: {
    Modal,
    List,
    Btn,
    Section,
  },

  emits: ['updateEssais', 'set-step', 'update:modelValue', 'set-route'],

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
    id: {
      type: [Number, String],
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      previousRouteName: 'essaiImportDataAssignement',
      isResume: false,
      sheets: [],
      enabled: [],
      jeton: null,
      step: 1,
      modalData: {},
      modal: {
        variableErrors: false,
      },
      componentKey: 0,
      analysisReports: [],

      list: {
        headers: [
          { label: 'Désignation de la variable' },
          { label: 'Cible' },
          { label: 'Organe' },
          { label: '% des données à importer' },
          { label: 'Erreurs' },
        ],
        col: ['designation', 'cible', 'organe', 'to_import', 'errors'],
        key: 'id',
      },

      list_errors: {
        headers: [
          { label: 'Erreur' },
          { label: 'Coordonnée géographique' },
          { label: 'Organe' },
          { label: 'Echantillon' },
          { label: 'Valeur dans le fichier' },
          { label: 'Détail de l\'erreur' },
        ],
        col: ['number', 'coos', 'organe', 'echantillon', 'value', 'details'],
        key: 'id',
      },
    }
  },

  watch: {
    modelValue() {
      if (this.modelValue) {
        this.import()
      }
      this.$emit('update:modelValue', false)
    },
    previousRouteName() {
      this.isResume = this.previousRouteName.includes('Report') ?? false
      if (this.isResume) {
        this.step = 2
        this.$emit('set-step', this.step)
        this.suppressColFromList()
      }
    },
    analysisReports() {
      this.$nextTick(() => {
        this.componentKey += 1
      })
    },
  },

  mounted() {
    this.jeton = this.$store.getters['io/jeton']
    this.enabled = this.$store.getters['io/enabled']
    this.analysisReports = this.$store.getters['io/analysisResults']
    this.analysisReports = this.analysisReports.filter((item) => 'id' in item)
    this.previousRouteName = 'essaiImportDataAssignement'
    if (this.$route.name.includes('Resume')) {
      this.previousRouteName = 'essaiImportReport'
      this.step = 2
      this.suppressColFromList()
    }
    this.$emit('set-step', this.step)
    this.sheets = this.$store.getters['io/sheets']

    this.$emit('set-route', {
      previousRouteName: this.previousRouteName,
      routeParams: { id: this.$route.params.id },
    })
  },

  methods: {
    import() {
      this.emitter.emit('open-loader')
      const enabled = this.formatEnabled()
      const { jeton } = this
      if (jeton) {
        this.fetchService.post(`essai/${this.$route.params.id}/evenement/fiche/notation/fichier/${jeton}/import`, enabled)
          .then((response) => {
            const { meta } = response
            if (meta.success) {
              this.emitter.emit('close-loader')
              this.emitter.emit('alert', {
                type: 'success',
                content: "L'import des données a été effectué.",
              })
              this.previousRouteName = 'essaiImportReport'
              this.$router.push({ name: 'essaiImportResume' })
            } else {
              this.emitter.emit('close-loader')
              this.emitter.emit('alert', {
                type: 'success',
                content: "Un problème est survenu lors de l'import des données.",
              })
            }
          })
      }
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    closeModal(modal) {
      this.modal[modal] = false
      this.modalData = {}
    },

    formatEnabled() {
      const datas = []
      this.enabled.forEach((variables, index) => {
        const base = this.sheets[index].variables
        Object.keys(base).forEach((i) => {
          base[i] = variables[i] ? { id: parseInt(variables[i], 10) } : null
        })
        datas.push({
          variables: base,
        })
      })
      return datas
    },

    suppressColFromList() {
      this.list.headers.pop()
      this.list.col.pop()
      this.list.headers[this.list.headers.length - 1] = { label: '% des données importées' }
    },
  },
}
</script>

<style lang="scss" scoped>

  .error-block {
    display: flex;
    gap: $gutter-half;
    align-items: center;

    > span {
      color: $color-error;
      font-weight: $font-weight-semibold;
    }
  }

  .btn-error {
    background-color: $color-error;

    @include hocus() {
      background-color: lighten($color-error, 10%) !important;
    }
  }

  .list-error-modal {
    margin-top: $gutter !important;

    ::v-deep(.list-body) {
      border-top: none;
    }
  }
  .error-list-item {
    & + & {
      margin-top: $gutter-half;
    }
  }
  .table--trial-import {
    ::v-deep(table) {
      th,
      td {
        &:first-child {
          border-left: 1px solid $color-primary-dark;
        }
      }

      tr:first-child {
        th:first-child {
          position: initial;
          font-size: $font-size-big;
          text-transform: uppercase;
          background-color: $color-primary-dark;
        }
        th:not(:first-child) {
          // outline: 4px dashed blueviolet;
          // max-width: 40rem;
          // white-space: break-spaces;
        }
      }
    }

    .table-col-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $gutter-half;
      text-align: left;
    }
  }

  .input-file--import {
    padding-right: $gutter-half;
    padding-left: 5.2rem + $gutter-half;
    @include v-padding($gutter-half);

    @include bp('sm') {
      display: flex;
      align-items: center;
      gap: $gutter;

      ::v-deep(label) {
        margin-bottom: 0;
        font-size: $font-size-big;
      }

      ::v-deep(.input) {
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-start;
        gap: $gutter-half;
      }

      ::v-deep(.input-file-filename) {
        margin-bottom: 0;
      }
      ::v-deep(.input-file-label) {
        margin-bottom: 0;
      }
    }

    @include bp($bp-expanded-container) {
      padding-right: $gutter;
      padding-left: 5.2rem + $gutter;
    }
  }
  .essai-statut {
    height: 15px;
    width: 15px;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    transform: rotate(45deg);

    &--filled {
      border: 1px solid $color-primary;
      background: $color-primary;
    }
  }
</style>
